$white: rgba(255, 255, 255, 1);
$mavi: var(--oksijen-mavi);
$maviDark: var(--oksijen-mavi-dark);

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
    background: rgb(255, 255, 255, 0.6);
    backdrop-filter: blur(10px);
    transition: all 0.5s;
    z-index: 997;
    height: 5rem;
    font-size: 14px;
    top: 0 !important;
}

#header.header-scrolled {
    background: rgb(255, 255, 255, 0.8);
    top: 0 !important;
}

#header .logo {
    font-size: 30px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
}

#header .logo a {
    color: var(--oksijen-turuncu);
}

#header .logo img {
    width: 8rem;
    height: auto;
}

@media (max-width: 420px) {
    #header .logo img {
        width: 5rem;
        height: auto;
    }
}

@media (max-width: 991px) {
    .header-actions-btn{
        font-size: 10px !important;
        text-wrap: wrap !important;
    }
}

.header-inner-pages {
    background: rgba(5, 87, 158, 0.9) !important;
}

.topbar-inner-pages {
    background: rgba(6, 98, 178, 0.9) !important;
}

/*--------------------------------------------------------------
    # Navigation Menu
--------------------------------------------------------------*/
/**
  * Desktop Navigation
  */
.navbar {
    padding: 0;
}

.navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
    flex-wrap: wrap;
}

.navbar li {
    position: relative;
}

.navbar>ul>li {
    position: relative;
    white-space: nowrap;
    padding: 10px 0 10px 24px;
}

.navbar a,
.navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3px;
    font-size: 14px;
    color: $mavi;
    white-space: nowrap;
    transition: 0.3s;
    position: relative;
}

.navbar a i,
.navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
}

.navbar>ul>li>a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: var(--oksijen-turuncu);
    visibility: hidden;
    width: 0px;
    transition: all 0.3s ease-in-out 0s;
}

.navbar>ul>li.active>a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: var(--oksijen-turuncu);
    visibility: visible;
    transition: all 0.3s ease-in-out 0s;
}

.navbar>ul>li.active>ul>li.active>a {
    color: var(--oksijen-turuncu);
}

.navbar a:hover:before,
.navbar li:hover>a:before,
.navbar .active:before {
    visibility: visible;
    width: 100%;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
    color: $maviDark;
}

.navbar .dropdown {
    cursor: pointer !important;
}

.navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: $white;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
    border-radius: 8px;
}

.navbar .dropdown ul li {
    min-width: 200px;
}

.navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
    color: var(--oksijen-mavi);
    white-space: normal;
}

.navbar .dropdown ul a i {
    font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
    color: var(--oksijen-turuncu);
}

.navbar .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
}

.navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
}

@media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
        left: -90%;
    }

    .navbar .dropdown .dropdown:hover>ul {
        left: -100%;
    }
}

/**
  * Mobile Navigation
  */
.mobile-nav-toggle {
    color: $maviDark;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
    color: var(--oksijen-turuncu);
}

@media (max-width: 991px) {
    .mobile-nav-toggle {
        display: block;
    }

    .navbar ul {
        display: none;
    }
}

.navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(5, 74, 133, 0.9);
    transition: 0.3s;
    z-index: 999;
    height: 100vh;
    width: auto;
}

.navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
}

.navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 10px;
    background-color: $white;
    overflow-y: auto;
    transition: 0.3s;
}

.navbar-mobile>ul>li {
    padding: 0;
}

.navbar-mobile a,
.navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: var(--oksijen-mavi-dark);
}

.navbar-mobile a:hover:before,
.navbar-mobile li:hover>a:before,
.navbar-mobile .active:before {
    visibility: hidden;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
    color: var(--oksijen-turuncu);
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
    margin: 15px;
}

.navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: $white;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
    min-width: 200px;
}

.navbar-mobile .dropdown ul a {
    padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
    font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
    color: var(--oksijen-turuncu);
}

.navbar-mobile .dropdown>.dropdown-active {
    display: block;
}

.header-actions-btn {
    background: var(--oksijen-turuncu);
    color: rgba(255, 255, 255, 1) !important;
    border-radius: 4px;
    padding: 6px 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: 0.3s;
    font-size: 14px;
    font-weight: 600;
    border: none;
    outline: none;

    &:focus {
        outline: none;
    }

    &:hover {
        background: rgba(17, 17, 17, 1) !important;
        color: rgba(255, 255, 255, 1) !important;
    }

    @media (max-width: 992px) {
        text-align: center;
        font-size: 12px;
        padding: 4px 15px;
    }
}
